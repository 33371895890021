<template>
    <div class="productTab">
        <v-row class="pa-2" v-if="products.length > 0">
            <v-col cols="6" v-for="(item,i) in products" :key="i">
                <v-card class="">
                    <v-card-title class="align-center justify-space-between">
                            <div style="font-size:18px;">
                                <h4 class="primary--text mb-0 pb-0"> {{item.label.value}}</h4>
                            </div>
                            <span style="min-width:75px;">
                                <v-btn v-if="$func.hasRight('lead/update')" @click.stop="getForm('/contact/' + $route.params.contact_id + '/getItemForm?type=' + item.type.value + '&id=' + item.id.value)" depressed text class="square">
                                    <v-icon dark small color="primary"> $pencil_d </v-icon>
                                </v-btn>

                                <v-btn v-if="$func.hasRight('lead/update')" @click.stop="getForm('/contact/' + $route.params.contact_id + '/checkBeforeDeleteItem?id=' + item.id.value)" depressed text class="square">
                                    <v-icon dark small color="warning"> $trashalt_d </v-icon>
                                </v-btn>
                            </span>
                    </v-card-title>
                    <v-card-text>
                        <ul>
                            <li><b>{{$t('reference')}} :</b> #V{{item.id.value}}</li>
                            <li v-for="(data, index) in item" :key="'data_'+index" v-show="data.value && data.value != '' && !['id', 'options', 'type', 'label'].includes(index)">
                                <b>{{$t(index)}} :</b>
                                <span class="text-left" v-if="data.type == 'date'">&nbsp;{{parseUsDateToFr(data.value)}}</span>
                                <span class="text-left" v-else-if="data.type == 'datetime'">&nbsp; {{parseFullDateToFr(data.value)}}</span>
                                <span class="text-left" v-else>&nbsp;{{data.value}}<span v-if="data.suffix"> {{data.suffix}}</span></span> 
                            </li>
                            <li v-for="(option, index) in item.options.value" :key="index" class="block">
                                <span class="text-right"><b>{{$t(option.reference)}} :</b></span>
                                <span class="text-left">&nbsp;{{option.value}}</span>
                            </li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row  class="pa-2 pt-4" v-else>
            <v-col cols="12">
                <v-alert text border="left" color="info">
                    {{ $t('noProductFound') }}
                </v-alert>
            </v-col>
        </v-row>
        
        <FormDialogComponent
        v-if="form"
        ref="componentForm"
        
        :form="form"
        :action="'SET_DATA'"
        />
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import dateManipulation from '@/mixins/dateManipulation'
import getForm from "@/mixins/mixins";
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";

export default {
    name:"productTabComponent",
    mixins: [dateManipulation, getForm],
    components: {FormDialogComponent},
    data() {
        return {
            products: [],
            form: null
        }
    },
    created(){
        GenericDataService.getData("/contact/" + this.$route.params.contact_id + "/getContactItems").then((response) => {
            this.products = response.data.data
        })
    },
    watch: {
        $route(to, from) {
            GenericDataService.getData("/contact/" + this.$route.params.contact_id + "/getContactItems").then((response) => {
                this.products = response.data.data
            })
        },
    },
    methods: {
        customRefresh(){
            GenericDataService.getData("/contact/" + this.$route.params.contact_id + "/getContactItems").then((response) => {
                this.products = response.data.data
            })
        }
    },
    
}
</script>
<style lang="scss">
    .productTab {
        display:flex;
        flex-wrap:wrap;
        .v-window__prev, .v-window__next {
            background:none !important;
            margin:0 !important;
        }
        h4 {
            border-bottom: 0px !important;
            text-transform: none;
            font-size:18px;
        }
    }
</style>